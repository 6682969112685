import request from '@/utils/request'

// 查询所有公司
export function getCompanyList() {
  return request({
    url: '/company/get/all',
    method: 'get'

  })
}

// 通过id获取公司 /company/{id}
export function getCompany(ucId) {
  return request({
    url: `/company/get/${ucId}`,
    method: 'get'

  })
}

// 部门分页列表
export function searchDepartment(searchForm) {
  return request({
    url: '/department/get/page',
    method: 'get',
    params: searchForm
  })
}

// 添加部门
export function addUd(addForm) {
  return request({
    url: '/department/add',
    method: 'post',
    data: addForm
  })
}

// 通过Id获取部门数据
export function getIdData(udId) {
  return request({
    url: `/department/get/${udId}`,
    method: 'get'
    //   params:{"udId" : udId}
  })
}

export function gettree(ucId) {
  return request({
    url: `/department/get/${ucId}/tree`,
    method: 'get'
    //   params:{"udId" : udId}
  })
}

// 编辑部门
export function editUdIdData(editForm) {
  return request({
    url: '/department/update',
    method: 'put',
    data: editForm
  })
}

// 通过Id删除部门----------------------注意参数
export function deleteUd(udId) {
  return request({
    url: `/department/delete/${udId}`,
    method: 'delete'
    // params:{"udId" : udId}
  })
}

// /通过用户登录的Id查出所拥有的系统再通过系统的系统Id,查询所有的仓库
export function getWarehouse(usId) {
  return request({
    url: 'department/get/warehouse/all',
    method: 'get',
    params: {

      usId: usId
    }
  })
}

// 查询当前用户所拥有的系统  /userSystemMap/get/userId/{id}

export function getUserSystem() { // [-------------------------------根据登录用户ID]
  return request({
    url: `/userSystemMap/get/userId`,
    method: 'get'

  })
}

// 查询公司下所有部门/department/{ucId}/udNames
export function getCompanyUd(ucId) {
  return request({
    url: `/department/get/${ucId}/udNames`,
    method: 'get'

  })
}

// 查询用户拥有的仓库  /department/get/warehouse/all
export function getUserSysAndWare() {
  return request({
    url: '/department/get/warehouse/all',
    method: 'get'

  })
}
